(function () {
  app.controller("CombineRoomCtrl", [
    "$scope",
    "$timeout",
    function ($scope, $timeout) {
      // if ($scope.secondary_db) {
      //   $scope.step_keys = { occupant: 0, condition: 1, passport: 2, invitation: 3 };
      // }else{
      //   $scope.step_keys = { occupant: 0, condition: 1, invitation: 2};
      // }
      $scope.step_keys = { occupant: 0, condition: 1, passport: 2, invitation: 3 };

      $scope.current_step = 0;
      $scope.error_massage = "";
      $scope.input_occupant = null;
      $scope.conditions = [
        {
          title:
            "Rooming together is a mutual decision. You must already know the email address for the person you want to room with before you can invite them, and an email invitation will be sent automatically to them from the system on your behalf. Invited roommates have the option to accept or reject your invitation. It is your responsibility as the ROOM HOST to manage your invites but review YOUR ROOM page to ensure that your roommates have accepted, and your room is finalized. If someone rejects your invite, you will be able to invite more roommates until your room is full. If you do not fill your room at the selected occupancy level by the deadline, you and your roommates will be charged applicable fees due associated with a lower room occupancy. You hereby agree to pay the additional fees if you are unable to complete your room with the number of roommates to fit the occupancy level you select.",
          checked: false,
        },
        {
          title:
            "Inform your roommates if you have selected to Decrease Your Room Occupancy and that in this case, they will be assessed additional charges as a result.",
          checked: false,
        },
        {
          title:
            "Email: support_email regarding any special accommodations or requests about room type as soon as you are aware of them. Failure to inform us of any special needs or requests in a timely manner will alleviate company_name of any liability, perceived or otherwise, or responsibility to provide them or ask to have them provided by any other vendor or provider.",
          checked: false,
        },
        {
          title:
            "Once roommate selection is finalized, no changes can be made without contacting us by emailing support_email.  All changes to rooming lists need to be made prior to 120 days before the start of the trip.",
          checked: false,
        },
      ];
      $scope.email_user = "";
      $scope.invite_user_list = [];
      $scope.number_must_invite = 0;

      $scope.init = function () {};

      $scope.removeUser = function (user) {
        var index = $scope.invite_user_list.indexOf(user);
        $scope.invite_user_list.splice(index, 1);
      };

      $scope.addUser = function () {
        $scope.error_massage = "";

        if ($scope.email_user.trim() == "") {
          return;
        }

        var isAdded =
          $.grep($scope.invite_user_list, function (u) {
            return u.email === $scope.email_user;
          }).length > 0;

        if (isAdded) {
          return;
        }

        var params = {
          order_id: $scope.order.id,
          inviter_id: $scope.order.buyer_id,
          event_id: $scope.order.event_id,
          trip_date_id: $scope.trip_date.id,
          package: $scope.package.package_name,
          room_type_name: $scope.package.room_type_name,
          product_name: $scope.package.product_name,
          email: $scope.email_user.trim(),
        };

        $.get($scope.find_roommate_path, params, function (rs) {
          $timeout(function () {
            if (rs.succeed && rs.data) {
              $scope.invite_user_list.push(rs.data);
            } else {
              $scope.error_massage = rs.message;
            }
          });
        });
      };

      $scope.validateInputOccupantStep = function () {
        $scope.error_massage = "";

        if ($.trim($scope.input_occupant) == "") {
          $scope.error_massage = "Please enter #occupants";
          return false;
        } else if ($scope.input_occupant <= 0) {
          $scope.error_massage = "Min number of occupants is 1!";
          return false;
        }else if ($scope.max_occupant == 0) {
          $scope.error_massage =
            "Please select a room type first!";
          return false;
        } else if ($scope.input_occupant > $scope.max_occupant) {
          $scope.error_massage =
            "Max of number occupants is " + $scope.max_occupant + "!";
          return false;
        }else if (!$scope.trip_date_selection && $scope.trip_date_selection_on) {
          $scope.error_massage =
            "Please choose a trip date";
          return false;
        }
        return true;
      };

      $scope.validateConditionStep = function () {
        $scope.error_massage = "";

        if (
          $.grep($scope.conditions, function (x, i) {
            return !x.checked;
          }).length > 0
        ) {
          $scope.error_massage = "Please agree all conditions!";
          return false;
        }

        return true;
      };

      $scope.validateInviteStep = function () {
        $scope.error_massage = "";

        if ($scope.invite_user_list.length != $scope.number_must_invite) {
          $scope.error_massage =
            "Please add more " +
            ($scope.number_must_invite - $scope.invite_user_list.length) +
            " people.";
          return false;
        }

        return true;
      };

      $scope.validateNextButton = function () {
        if ($scope.current_step == $scope.step_keys.occupant) {
          return (
            $.trim($scope.input_occupant) != "" &&
            ($scope.trip_date_selection || !$scope.trip_date_selection_on) &&
            $scope.input_occupant > 0 &&
            $scope.input_occupant <= $scope.max_occupant
          );
        }

        if ($scope.current_step == $scope.step_keys.condition) {
          return (
            $.grep($scope.conditions, function (x, i) {
              return !x.checked;
            }).length == 0
          );
        }

        if ($scope.current_step == $scope.step_keys.invitation) {
          return $scope.invite_user_list.length == $scope.number_must_invite;
        }
      };

      $scope.nextStep = function () {
        if (
          $scope.current_step == $scope.step_keys.occupant &&
          $scope.validateInputOccupantStep()
        ) {
          if ($scope.input_occupant == 1) {
            $scope.invite_user_list = [];
            executeCombineRoom();
            return;
          }

          $scope.current_step++;
          return;
        }

        if (
          $scope.current_step == $scope.step_keys.condition &&
          $scope.validateConditionStep()
        ) {
          $scope.number_must_invite = $scope.input_occupant - 1;
          $scope.current_step++;
          return;
        }

        if (
          $scope.current_step == $scope.step_keys.invitation &&
          $scope.validateInviteStep()
        ) {
          executeCombineRoom();
        }
      };

      $scope.backPreviousStep = function () {
        $scope.current_step -= 1;
        $scope.error_massage = "";

        // if($scope.input_occupant == 1){
        //     $scope.current_step -= 1;
        // }
      };

      $scope.invite_members = function () {
        var params = {
          users: angular.toJson($scope.invite_user_list),
        };

        $.post($scope.execute_invite_rommates_path, params, function (rs) {
          $timeout(function () {
            if (!rs.succeed) {
              if (rs.users) {
                $scope.invite_user_list = rs.users;
              }

              if (rs.message) {
                $scope.error_massage = rs.message;
              }
            }
          });
        });
      };

      function executeCombineRoom() {
        var params = {
          users: angular.toJson($scope.invite_user_list),
          order_id: $scope.order.id,
          trip_date_id: $scope.trip_date.id,
          booking_order_item_id: $scope.package.id,
          occupants: $scope.input_occupant,
          room_fee: $scope.room_price_data[$scope.input_occupant],
          trip_date_selection_id: $scope.trip_date_selection_on ? $scope.trip_date_selection : null,
          room_type_id: $scope.room_type_id
        };

        $.post($scope.execute_combine_room_path, params, function (rs) {
          $timeout(function () {
            if (!rs.succeed) {
              if (rs.users) {
                $scope.invite_user_list = rs.users;
              }

              if (rs.message) {
                $scope.error_massage = rs.message;
              }
            }
          });
        });
      }

      $scope.selectRoomType = function(roomType) {
        if (roomType.stock > 0 || roomType.stock == null) {
          $scope.room_type_id = roomType.id;
          $scope.max_occupant = roomType.max_occupants_per_room;
          $scope.room_price_data = JSON.parse(roomType.room_price_data.replace(/=>/g, ':'));
          $scope.validateInputOccupantStep();
        }
    };
    },
  ]);
}.call(this));
